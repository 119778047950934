import React from "react";
import "./home.css";
import { Link } from "react-router-dom";

function Navbar() {
  return (
    <div className='navbar-container'>
      <Link to='/'>
        <div className='logo'>
          <img src='https://ik.imagekit.io/wilywolves/logo_QZ_ZQnI3J.png?ik-sdk-version=javascript-1.4.3&updatedAt=1646080142391' />
        </div>
      </Link>
      <Link to='/about'>
        <div className='about'>About</div>
      </Link>
    </div>
  );
}

export default Navbar;
