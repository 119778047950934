import React, { useState, useEffect } from "react";
import Work from "./work";
import Hello from "./hello";
import { Link } from "react-scroll";
import "./home.css";

function Home() {
  return (
    <div className='home-container'>
      <div className='hero-container'>
        <div className='text-container'>
          <div className='title'>
            Digital <br></br> Designer
          </div>
          <div className='description'>
            Hello! my name is Neil. I am a designer combining illustration,
            visualaztion, and problem solving into beautiful products. I code
            for fun.
          </div>
          <Link to='to-work' smooth={true} duration={1000}>
            <button className='work'>View Work</button>
          </Link>
        </div>
      </div>
      <div className='client-container'>
        <div className='text-container-client'>
          <div className='title-clients'>Clients</div>
          <div className='description-clients'>
            Over the last 9 years, I have worked alongside many great companies,
            across different industries.
          </div>
        </div>
        <div className='showcase-container'>
          <div className='showcase-box'>
            <img src='https://ik.imagekit.io/wilywolves/nike__eJ-A6N1q.png?ik-sdk-version=javascript-1.4.3&updatedAt=1646072860507' />
          </div>
          <div className='showcase-box' id='gm'>
            <img src='https://ik.imagekit.io/wilywolves/gm_66m4YZats.png?ik-sdk-version=javascript-1.4.3&updatedAt=1646072860342' />
          </div>
          <div className='showcase-box' id='nyt'>
            <img src='https://ik.imagekit.io/wilywolves/times_rCnZa-uZ0.png?ik-sdk-version=javascript-1.4.3&updatedAt=1646072860289' />
          </div>
          <div className='showcase-box' id='unknown'>
            <img src='https://ik.imagekit.io/wilywolves/unk_ufIzsvJIU.png?ik-sdk-version=javascript-1.4.3&updatedAt=1646073834674' />
          </div>
          <div className='showcase-box'>
            <img src='https://ik.imagekit.io/wilywolves/fca_1ogIdm4ZHXa.png?ik-sdk-version=javascript-1.4.3&updatedAt=1646073451237' />
          </div>
          <div className='showcase-box' id='mj'>
            <img src='https://ik.imagekit.io/wilywolves/mensjournal_AOD3M7WTaNl.png?ik-sdk-version=javascript-1.4.3&updatedAt=1646072860343' />
          </div>
        </div>
      </div>
      <div className='tools-container'>
        <div className='text-contianer-tools'>
          <div className='title-tools'>Tools of the Trade</div>
          <div className='description-tools'>
            As a multi-faceted designer over the past 9 years I have learned to
            use a wide range of tools. These are some of my current favorites
          </div>
          <div className='image-tools'>
            <img src='https://ik.imagekit.io/wilywolves/skills_xCG5NlYgZZH.png?ik-sdk-version=javascript-1.4.3&updatedAt=1646078271892' />
          </div>
        </div>
      </div>

      <div className='work-container' id='to-work'>
        <Work />
      </div>
      <div className='hello-container-home'>
        <Hello />
      </div>
    </div>
  );
}

export default Home;
