import React, { useEffect } from "react";
import "./page.css";
import { Link } from "react-router-dom";

function Unknown() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='page-container'>
      <div className='page-header'>
        <div className='title-box-container'>
          <div className='project-title'>Unknown</div>
          <div className='project-catagories'> Web • UI • Visual</div>
        </div>
        <div className='page-header-image'>
          <img src='https://ik.imagekit.io/wilywolves/unknownmain_8v4N3_bik8E.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1641333162894' />
        </div>
      </div>
      <div className='page-steps-container'>
        <div className='page-steps-box'>
          <div className='steps-text-contianer'>
            <div className='steps-title'>01 — START</div>
            <div className='page-title-text'>Rebrand</div>
            <div className='page-description-text'>
              Unknown had recently rebranded their look and was looking for a
              new updated website design to match their growing customer base.
              They wanted a unique look that matched their technology base.
            </div>
          </div>
        </div>
      </div>
      <div className='page-first-image'>
        <img src='https://ik.imagekit.io/wilywolves/unknowndisplay_rXAz3cf-r.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1646321118488' />
      </div>
      <div className='page-steps-container'>
        <div className='page-steps-box'>
          <div className='steps-text-contianer'>
            <div className='steps-title'>02 — The Process</div>
            <div className='page-title-text'>Building the sales</div>
            <div className='page-description-text'>
              The main goal for Unknown's website was to show the products they
              had, and upon purchase, an easy way for clients to manage. We
              worked on a new sales page that better highlighted popular plans
              using a new color/badge system. Along with a new sales page, we
              reworked the dashboard, drawing the focus to the most important
              elements clients needed.
            </div>
          </div>
        </div>
      </div>
      <div className='page-first-image'>
        <img src='https://ik.imagekit.io/wilywolves/unknownpages_LOGHnYa7BcT.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1646321418494' />
      </div>
      <div className='page-first-image'>
        <img src='https://ik.imagekit.io/wilywolves/unknown_uikit_j573G8T0ObNm.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1646840119548' />
      </div>
      <div className='page-first-image'>
        <img src='https://ik.imagekit.io/wilywolves/success_pQksxdEVG8.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1646840375066' />
      </div>

      <div className='purchase-box-container'>
        <div className='insert'>
          Utilizing color, weight, and sizing we can encourage the customer to
          choose the best valued plan.
        </div>
        <div className='purchase-box' id='best'>
          <div className='purchase-top-container'>
            <div className='purchase-box-title'>Plan</div>
            <div className='purchase-input-box'>25</div>

            <div className='purchase-box-price'>$55</div>
          </div>
          <div className='purchase-button'>CTA</div>
        </div>
        <div className='purchase-box' id='mid'>
          <div className='purchase-top-container'>
            <div className='purchase-box-title'>Plan</div>
            <div className='purchase-input-box'>25</div>

            <div className='purchase-box-price'>$65</div>
          </div>
          <div className='purchase-button'>CTA</div>
        </div>
        <div className='purchase-box' id='gold'>
          <div className='purchase-top-container'>
            <div className='purchase-box-title'>Plan</div>
            <div className='purchase-input-box'>25</div>

            <div className='purchase-box-price'>$75</div>
          </div>
          <div className='purchase-button'>CTA</div>
        </div>
      </div>

      <div className='page-assets-image'>
        <img src=' https://ik.imagekit.io/wilywolves/unknownassets_68OjZlmyvSq.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1646322255557' />
      </div>
      <div className='page-first-image'>
        <img src='https://ik.imagekit.io/wilywolves/unknownfull_LIhyEazXdKY.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1646340617575' />
      </div>
      <div className='page-assets-image' id='grey'>
        <img src=' https://ik.imagekit.io/wilywolves/unknowndashboard_hxPJOiQN9.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1646321758824' />
      </div>

      <div className='page-steps-container'>
        <div className='page-steps-box'>
          <div className='steps-text-contianer'>
            <div className='steps-title'>03 — Marketing Material</div>
            <div className='page-title-text'>Merchandise</div>
            <div className='page-description-text'>
              Unknown wanted customized merchandise, leading us to work on
              building out a Shopify store, along with that marketing material.
              Below is an email blast created for the release.
            </div>
          </div>
        </div>
      </div>
      <div className='page-first-image'>
        <img src='https://ik.imagekit.io/wilywolves/unknownemail_7KzcHq-9K.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1646322628785' />
      </div>
      <div className='page-first-image'>
        <img src='https://ik.imagekit.io/wilywolves/hoodie_AMoSWF00UD_.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1646322822048' />
      </div>

      <div
        className='next-project'
        style={{
          backgroundImage:
            "url(" +
            "https://ik.imagekit.io/wilywolves/scoutbanner_T-pRdRKEah.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1641838219898" +
            ")",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          opacity: "0.75",
        }}
      >
        <Link to='/scout'>
          <div className='next-project-button'>Next Project</div>
        </Link>
      </div>
    </div>
  );
}

export default Unknown;
