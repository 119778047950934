import React from "react";
import { Link } from "react-router-dom";
import "./work.css";

function Work() {
  return (
    <div className='portfolio-container'>
      <div className='work-box-container'>
        <Link to='/ardentink'>
          <div className='work-box'>
            <div className='work-image'>
              <img src='https://ik.imagekit.io/wilywolves/ardentcover_pu_luDUD1F.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1646104745950' />
            </div>
            <div className='work-bottom-card'>
              <div className='work-title'>Ardent Ink</div>
              <div className='work-categories'>
                Branding • Marketing • Product Design
              </div>
            </div>
          </div>
        </Link>
        <Link to='/wilywolves'>
          <div className='work-box'>
            <div className='work-image'>
              <img src='https://ik.imagekit.io/wilywolves/wwicon_A1l16q9j9At.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1640805948787' />
            </div>
            <div className='work-bottom-card'>
              <div className='work-title'>Wily Wolves</div>
              <div className='work-categories'>
                Web • UI • Branding • Visual
              </div>
            </div>
          </div>
        </Link>
        <Link to='/sargentstitle'>
          <div className='work-box'>
            <div className='work-image'>
              <img src='https://ik.imagekit.io/wilywolves/sargentsheader_6-uBAN8ncNa1.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1646103635779' />
            </div>
            <div className='work-bottom-card'>
              <div className='work-title'>Sargent's Title</div>
              <div className='work-categories'>
                Web • UI • Branding • Visual
              </div>
            </div>
          </div>
        </Link>

        <Link to='/unknown'>
          <div className='work-box'>
            <div className='work-image'>
              <img src='https://ik.imagekit.io/wilywolves/unknownheader_-6VUbXrrP.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1640885443615' />
            </div>
            <div className='work-bottom-card'>
              <div className='work-title'>Unknown</div>
              <div className='work-categories'>
                Web • UI • Branding • Visual
              </div>
            </div>
          </div>
        </Link>
        <Link to='/scout'>
          <div className='work-box'>
            <div className='work-image'>
              <img src='https://ik.imagekit.io/wilywolves/scoutheader_ill8lZ1-0.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1646103697127' />
            </div>
            <div className='work-bottom-card'>
              <div className='work-title'>Scout</div>
              <div className='work-categories'>UI Design</div>
            </div>
          </div>
        </Link>
        <Link to='/nyt'>
          <div className='work-box'>
            <div className='work-image'>
              <img src='https://ik.imagekit.io/wilywolves/nytcover_wlTrtoKh54_X.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1646103802779' />
            </div>
            <div className='work-bottom-card'>
              <div className='work-title'>New York Times</div>
              <div className='work-categories'>Visual </div>
            </div>
          </div>
        </Link>
        <Link to='/nike'>
          <div className='work-box'>
            <div className='work-image'>
              <img src='https://ik.imagekit.io/wilywolves/nikedetroit_6iE4N6460.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1647893442612' />
            </div>
            <div className='work-bottom-card'>
              <div className='work-title'>Nike</div>
              <div className='work-categories'>Visual </div>
            </div>
          </div>
        </Link>
        <Link to='/dodge'>
          <div className='work-box'>
            <div className='work-image'>
              <img src='https://ik.imagekit.io/wilywolves/dodgeheader_n3EdVFg-k.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1646341398182' />
            </div>
            <div className='work-bottom-card'>
              <div className='work-title'>Dodge</div>
              <div className='work-categories'>Visual</div>
            </div>
          </div>
        </Link>
        <Link to='/miscellaneous'>
          <div className='work-box'>
            <div className='work-image'>
              <img src='https://ik.imagekit.io/wilywolves/miscicon_JoYsf_0lE4.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1647890553325' />
            </div>
            <div className='work-bottom-card'>
              <div className='work-title'>Misc. Work</div>
              <div className='work-categories'>Visual</div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default Work;
