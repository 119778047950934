import React, { useEffect } from "react";
import "./page.css";
import { Link } from "react-router-dom";

function STC() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='page-container'>
      <div className='page-header'>
        <div className='title-box-container'>
          <div className='project-title'>Sargent's Title</div>
          <div className='project-catagories'>
            {" "}
            Web • UI • Branding • Visual
          </div>
        </div>
        <div className='page-header-image'>
          <img src='https://ik.imagekit.io/wilywolves/sargentsmockup_bwD7_P_k7.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1641319280694' />
        </div>
      </div>
      <div className='page-steps-container'>
        <div className='page-steps-box'>
          <div className='steps-text-contianer'>
            <div className='steps-title'>01 — START</div>
            <div className='page-title-text'>Web Design</div>
            <div className='page-description-text'>
              The goal was to refresh the look of sargentstitle.com to help
              approach new, upcoming generations. We studied similar market
              sites and put together a look that was inviting but remained
              unique to them.
            </div>
          </div>
        </div>
      </div>
      <div className='page-first-image'>
        <img src='https://ik.imagekit.io/wilywolves/sargentsdisplay__kdSFnQc1.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1646239833090' />
      </div>
      <div className='page-steps-container'>
        <div className='page-steps-box'>
          <div className='steps-text-contianer'>
            <div className='steps-title'>02 — The Process</div>
            <div className='page-title-text'>Revamp</div>
            <div className='page-description-text'>
              Sargent's had already identified the flow of each page of the
              website and wanted to maintain that. The process was to take their
              existing site and improve the visuals and design. This includes
              taking their existing site into Figma and redesigning it with new
              illustrations, typography, updated colors, and visuals.
            </div>
          </div>
        </div>
      </div>
      <div className='page-first-image'>
        <img src='https://ik.imagekit.io/wilywolves/figmasargents_-6m-Lg5i62.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1646240290988' />
      </div>
      <div className='page-first-image'>
        <img src='https://ik.imagekit.io/wilywolves/sargentsmobile_jkaIq0VLf.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1647981578777' />
      </div>
      <div className='video'>
        <video autoPlay muted loop playsInline>
          <source src='   https://ik.imagekit.io/wilywolves/sargentsiphone_IDsNOAIghUR.mp4?ik-sdk-version=javascript-1.4.3&updatedAt=1646239314036' />
        </video>
      </div>

      <div className='page-assets-image'>
        <img src=' https://ik.imagekit.io/wilywolves/sargentsguide_xR8Wwg1u45.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1647963766410' />
      </div>
      <div className='page-assets-image'>
        <img src='https://ik.imagekit.io/wilywolves/website_CJ9U3i-0sY.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1647967394719' />
      </div>
      <div className='page-assets-image' id='grey'>
        <img src=' https://ik.imagekit.io/wilywolves/sargentsicons_eOrFT9MsQ.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1646241424575' />
      </div>

      <div className='page-steps-container'>
        <div className='page-steps-box'>
          <div className='steps-text-contianer'>
            <div className='steps-title'>03 — Marketing Material</div>
            <div className='page-title-text'>Visual Upgrade</div>
            <div className='page-description-text'>
              Along with redesigning Sargent's website, I updated their logo to
              be more legible, and modern. I also helped create some new
              marketing visual graphics, the one below was created as a Facebook
              ad.
            </div>
          </div>
        </div>
      </div>
      <div className='page-first-image'>
        <img src='https://ik.imagekit.io/wilywolves/sargentslogo_TXJRRzDZK.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1647982984365' />
      </div>
      <div className='page-first-image'>
        <img src='https://ik.imagekit.io/wilywolves/sargentsad_d1Hw63_jJ.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1646241424710' />
      </div>

      <div
        className='next-project'
        style={{
          backgroundImage:
            "url(" +
            "https://ik.imagekit.io/wilywolves/unknownfull_LIhyEazXdKY.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1646340617575" +
            ")",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          opacity: "0.75",
        }}
      >
        <Link to='/unknown'>
          <div className='next-project-button'>Next Project</div>
        </Link>
      </div>
    </div>
  );
}

export default STC;
