import "./App.css";
import React from "react";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Wily from "./components/pages/wily";
import NYT from "./components/pages/nyt";
import Nike from "./components/pages/nike";
import STC from "./components/pages/stc";
import Unknown from "./components/pages/unknown";
import Ardent from "./components/pages/ardent";
import Scout from "./components/pages/scout";
import Dodge from "./components/pages/dodge";
import MISC from "./components/pages/misc";
import Navbar from "./components/navbar";
import Home from "./components/home";
import About from "./components/about";
import ReactGA from "react-ga";

const TRACKING_ID = "UA-207362152-4";
ReactGA.initialize(TRACKING_ID);
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  return (
    <div className='App'>
      <Router>
        <Navbar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/wilywolves' element={<Wily />} />
          <Route path='/scout' element={<Scout />} />
          <Route path='/nyt' element={<NYT />} />
          <Route path='/dodge' element={<Dodge />} />
          <Route path='/nike' element={<Nike />} />
          <Route path='/sargentstitle' element={<STC />} />
          <Route path='/ardentink' element={<Ardent />} />
          <Route path='/unknown' element={<Unknown />} />
          <Route path='/miscellaneous' element={<MISC />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
