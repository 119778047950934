import React, { useEffect } from "react";
import "./page.css";
import { Link } from "react-router-dom";

function Scout() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='page-container'>
      <div className='page-header'>
        <div className='title-box-container'>
          <div className='project-title'>Scout</div>
          <div className='project-catagories'> UI design</div>
        </div>
        <div className='page-header-image'>
          <img src='https://ik.imagekit.io/wilywolves/scoutbanner_T-pRdRKEah.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1641838219898' />
        </div>
      </div>
      <div className='page-steps-container'>
        <div className='page-steps-box'>
          <div className='steps-text-contianer'>
            <div className='steps-title'>01 — START</div>
            <div className='page-title-text'>Inventory Tracker</div>
            <div className='page-description-text'>
              Scout was working on revamping its web and native application. The
              flow of the application was set in place, so they were looking for
              visual upgrades.
            </div>
          </div>
        </div>
      </div>
      <div className='page-first-image'>
        <img src='https://ik.imagekit.io/wilywolves/scoutmobile_e6a_8k2L5Au.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1646324332377' />
      </div>
      <div className='page-steps-container'>
        <div className='page-steps-box'>
          <div className='steps-text-contianer'>
            <div className='steps-title'>02 — The Process</div>
            <div className='page-title-text'>Visual Elements</div>
            <div className='page-description-text'>
              I focused on highlighting important aspects of the inventory
              process, listed and sold items. Especially in the mobile
              application, I found that highlighting these events within the
              imagery saved space. I also took the opportunities to further save
              space and group items in containers, this helped with reading
              information that correlated.
            </div>
          </div>
        </div>
      </div>

      <div className='page-assets-image' id='grey'>
        <img src=' https://ik.imagekit.io/wilywolves/mobileupdate_EpLoMe2bW.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1646324925790' />
      </div>
      <div className='page-assets-image' id='grey'>
        <img src=' https://ik.imagekit.io/wilywolves/scoutdash_YNWRaYFqf.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1646324925875' />
      </div>
      <div className='page-assets-image' id='grey'>
        <img src='https://ik.imagekit.io/wilywolves/salespage_C8dxUmeOXrc1.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1646324925748' />
      </div>

      <div className='page-steps-container'>
        <div className='page-steps-box'>
          <div className='steps-text-contianer'>
            <div className='steps-title'>03 — Marketing Material</div>
            <div className='page-title-text'>Advertisements</div>
            <div className='page-description-text'>
              Along with helping redesign the application, I created marketing
              material for job listings, below is an example. I created all
              visual elements for this ad, including the 3d rendering.
            </div>
          </div>
        </div>
      </div>

      <div className='page-first-image'>
        <img src='https://ik.imagekit.io/wilywolves/scoutad_mzA9UlW1di3.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1646324575683' />
      </div>

      <div
        className='next-project'
        style={{
          backgroundImage:
            "url(" +
            "https://ik.imagekit.io/wilywolves/nytcoverimage_96kbK9T64.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1641317788014" +
            ")",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          opacity: "0.75",
        }}
      >
        <Link to='/nyt'>
          <div className='next-project-button'>Next Project</div>
        </Link>
      </div>
    </div>
  );
}

export default Scout;
