import React, { useEffect } from "react";
import "./page.css";
import { Link } from "react-router-dom";

function NYT() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='page-container'>
      <div className='page-header'>
        <div className='title-box-container'>
          <div className='project-title'>New York Times</div>
          <div className='project-catagories'>
            {" "}
            Illustration • Visual Design
          </div>
        </div>
        <div className='page-header-image'>
          <img src='https://ik.imagekit.io/wilywolves/nytcoverimage_96kbK9T64.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1641317788014' />
        </div>
      </div>
      <div className='page-steps-container'>
        <div className='page-steps-box'>
          <div className='steps-text-contianer'>
            <div className='steps-title'>01 — START</div>
            <div className='page-title-text'>Book Design</div>
            <div className='page-description-text'>
              Over the past 9 years, I have worked with St. Martins Press and
              NYT on many book cover designs. Here are a few of my favorites
              below.
            </div>
          </div>
        </div>
      </div>
      <div className='page-first-image'>
        <img src='https://ik.imagekit.io/wilywolves/nyt_RRPMkMelWO.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1646328631504' />
      </div>
      <div className='page-first-image'>
        <img src='https://ik.imagekit.io/wilywolves/hits__ShNdvObD.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1646330036610' />
      </div>
      <div className='page-first-image'>
        <img src='https://ik.imagekit.io/wilywolves/puzzle_8DA4Ih3_A.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1646330237628' />
      </div>
      <div className='page-first-image'>
        <img src='https://ik.imagekit.io/wilywolves/booksamples_wTPbnUTt9U.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1646665535142' />
      </div>
      <div
        className='next-project'
        style={{
          backgroundImage:
            "url(" +
            "https://ik.imagekit.io/wilywolves/detroitlarge_frr11KLbs.png?ik-sdk-version=javascript-1.4.3&updatedAt=1640718614934" +
            ")",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          opacity: "0.75",
        }}
      >
        <Link to='/nike'>
          <div className='next-project-button'>Next Project</div>
        </Link>
      </div>
    </div>
  );
}

export default NYT;
