import React from "react";

function About() {
  return (
    <div className='about-container'>
      <div className='page-first-image'>
        <img src='https://ik.imagekit.io/wilywolves/about_O700WPG6M.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1647876737014' />
      </div>
    </div>
  );
}

export default About;
