import React, { useEffect } from "react";
import "./page.css";
import { Link } from "react-router-dom";

function Nike() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='page-container'>
      <div className='page-header'>
        <div className='title-box-container'>
          <div className='project-title'>Nike</div>
          <div className='project-catagories'> Visual Design</div>
        </div>
        <div className='page-header-image'>
          <img src='https://ik.imagekit.io/wilywolves/detroitlarge_frr11KLbs.png?ik-sdk-version=javascript-1.4.3&updatedAt=1640718614934' />
        </div>
      </div>
      <div className='page-steps-container'>
        <div className='page-steps-box'>
          <div className='steps-text-contianer'>
            <div className='steps-title'>01 — START</div>
            <div className='page-title-text'>Lettering</div>
            <div className='page-description-text'>
              Nike Reached out to me to create lettering for their Detroit
              store. I worked through concepts of how this illustrative type
              could depict Detroit, focusing on the idea of rebirth. Working
              through ideas with the Nike team, we landed on this script
              lettering, feeling that the movement within the lettering gave
              this idea of a new, modern take on a classic style. I created
              digital mockups for the team to use to ideate how we could situate
              this lettering on merchandise, billboards, store signage, and
              more.
            </div>
          </div>
        </div>
      </div>
      <div className='page-first-image'>
        <img src='https://ik.imagekit.io/wilywolves/detroitmain_Lthxhi0nLN.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1647526484511' />
      </div>
      <div className='page-first-image'>
        <img src='https://ik.imagekit.io/wilywolves/sportsbra_tJnOGdn3XmES.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1647446069177' />
      </div>
      <div className='page-first-image'>
        <img src='https://ik.imagekit.io/wilywolves/basketball_8m8sfRQ78Y.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1647528267075' />
      </div>
      <div className='page-first-image'>
        <img src='https://ik.imagekit.io/wilywolves/detroitwall_CAZ9I5v8UZ.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1647528538143' />
      </div>
      <div className='page-first-image'>
        <img src='https://ik.imagekit.io/wilywolves/niketshirt_qNJS0wyn73.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1647529521155' />
      </div>

      <div className='page-first-image'>
        <img src='https://ik.imagekit.io/wilywolves/insidedetroit_V629HLaR9p.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1647528923468' />
      </div>

      <div
        className='next-project'
        style={{
          backgroundImage:
            "url(" +
            "https://ik.imagekit.io/wilywolves/pattern_XXjl7rqj0ylw.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1646343066915" +
            ")",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          opacity: "0.75",
        }}
      >
        <Link to='/dodge'>
          <div className='next-project-button'>Next Project</div>
        </Link>
      </div>
    </div>
  );
}

export default Nike;
