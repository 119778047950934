import React, { useEffect } from "react";
import "./page.css";
import { Link } from "react-router-dom";

function Wily() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='page-container'>
      <div className='page-header'>
        <div className='title-box-container'>
          <div className='project-title'>Wily Wolves</div>
          <div className='project-catagories'>
            {" "}
            Web • UI • Branding • Visual
          </div>
        </div>
        <div className='page-header-image'>
          <img src='https://ik.imagekit.io/wilywolves/wilywolvesheader_oTun8U4xs.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1640893273211' />
        </div>
      </div>
      <div className='page-steps-container'>
        <div className='page-steps-box'>
          <div className='steps-text-contianer'>
            <div className='steps-title'>01 — START</div>
            <div className='page-title-text'>NFT Application</div>
            <div className='page-description-text'>
              The goal was to build out an NFT project utilizing all of my
              skills. From creating the branding, illustrations, visuals, and
              coding, I created a full digital product. The goal of the
              application was to track your NFT stats like a stock trader
              monitors their assets.
            </div>
          </div>
        </div>
      </div>
      <div className='page-first-image'>
        <img src='https://ik.imagekit.io/wilywolves/wilywolvesbanner_2ftwdeltM.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1646163000434' />
      </div>
      <div className='page-steps-container'>
        <div className='page-steps-box'>
          <div className='steps-text-contianer'>
            <div className='steps-title'>02 — The Process</div>
            <div className='page-title-text'>How to build it</div>
            <div className='page-description-text'>
              To start with concepts, I sought out to find current pain points
              in analytics on trading platforms such as opensea. Talking with
              traders, I narrowed it down to six key points. The initialization
              of the application was starting with how the flow of the login
              system should be. Users would require owning the NFT to be
              verified. From there I needed to identify how to display the key
              anayltical data I had found from research. This application had to
              be both web and mobile based, since many traders noted they were
              often on the move.
            </div>
          </div>
        </div>
      </div>
      <div className='page-first-image'>
        <img src='https://ik.imagekit.io/wilywolves/collection_ooaGfw8gZ.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1646852242374' />
      </div>

      <div className='page-first-image'>
        <img src='https://ik.imagekit.io/wilywolves/uisketch_io0g9pFCI.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1646855498453' />
      </div>

      <div className='page-first-image'>
        <img src='https://ik.imagekit.io/wilywolves/wilyflow_cs06JdVS-.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1646235951433' />
      </div>
      <div className='page-first-image'>
        <img src='https://ik.imagekit.io/wilywolves/structure_LBTB8M88TT_.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1646860597232' />
      </div>
      <div className='page-first-image'>
        <img src='https://ik.imagekit.io/wilywolves/wireframe_3vCA9fQwjo.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1646842336259' />
      </div>

      <div className='video'>
        <video autoPlay muted loop playsInline>
          <source src='   https://ik.imagekit.io/wilywolves/login_aX4KHZ2swv.mp4?ik-sdk-version=javascript-1.4.3&updatedAt=1646238155091' />
        </video>
      </div>

      <div className='page-assets-image'>
        <img src=' https://ik.imagekit.io/wilywolves/wilyguides_AKZaOm3rtm.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1647962697515' />
      </div>
      <div className='video'>
        <video autoPlay muted loop playsInline>
          <source src='   https://ik.imagekit.io/wilywolves/ui_p7rYrOombS.mp4?ik-sdk-version=javascript-1.4.3&updatedAt=1647880561828' />
        </video>
      </div>
      <div className='page-assets-image' id='grey'>
        <img src=' https://ik.imagekit.io/wilywolves/cardsystem_JfCMd5Q7b.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1647878750424' />
      </div>
      <div className='page-assets-image' id='grey'>
        <img src=' https://ik.imagekit.io/wilywolves/illustrations_8TZH99cvrkh.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1646236085746' />
      </div>

      <div className='page-steps-container'>
        <div className='page-steps-box'>
          <div className='steps-text-contianer'>
            <div className='steps-title'>03 — Marketing Material</div>
            <div className='page-title-text'>What's for Sale?</div>
            <div className='page-description-text'>
              The key to any successful NFT project is marketing. While I lacked
              a true marketing department, I developed material for the project
              such as social media graphics, animations, and more. Everything
              within these visuals was created by me, including all assets and
              animations.
            </div>
          </div>
        </div>
      </div>
      <div className='page-first-image'>
        <img src='https://ik.imagekit.io/wilywolves/promowolves_UG5lnkWPgXw.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1646166145882' />
      </div>
      <div className='page-assets-image' id='black'>
        <img src='https://ik.imagekit.io/wilywolves/hideout_wgxZOCCKq9.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1646689437935' />
      </div>
      <div className='video'>
        <video autoPlay muted loop playsInline>
          <source src='https://ik.imagekit.io/wilywolves/Untitled__2__C7kqmavN4.mp4?updatedAt=1636433853482' />
        </video>
      </div>
      <div className='video'>
        <video autoPlay muted loop playsInline>
          <source src='https://ik.imagekit.io/wilywolves/hideout_SbXSPelT4.mp4?updatedAt=1640714571229' />
        </video>
      </div>

      <div
        className='next-project'
        style={{
          backgroundImage:
            "url(" +
            "https://ik.imagekit.io/wilywolves/sargentsmockup_bwD7_P_k7.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1641319280694" +
            ")",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          opacity: "0.75",
        }}
      >
        <Link to='/sargentstitle'>
          <div className='next-project-button'>Next Project</div>
        </Link>
      </div>
    </div>
  );
}

export default Wily;
