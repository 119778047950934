import React, { useEffect } from "react";
import "./page.css";
import { Link } from "react-router-dom";

function MISC() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='page-container'>
      <div className='page-header'>
        <div className='title-box-container'>
          <div className='project-title'>Misc. Designs</div>
          <div className='project-catagories'> Visual Design</div>
        </div>
        <div className='page-header-image'>
          <img src='https://ik.imagekit.io/wilywolves/union_ZwCH03jo3p.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1647890078167' />
        </div>
      </div>
      <div className='page-steps-container'>
        <div className='page-steps-box'>
          <div className='steps-text-contianer'>
            <div className='steps-title'>01 — START</div>
            <div className='page-title-text'>Design Work</div>
            <div className='page-description-text'>
              A collection of misc. design work ranging from email design,
              infographics, advertistments, and more.
            </div>
          </div>
        </div>
      </div>
      <div className='page-first-image'>
        <img src='https://ik.imagekit.io/wilywolves/report_CQ8hLO0eQ.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1647889968302' />
      </div>
      <div className='page-first-image'>
        <img src='https://ik.imagekit.io/wilywolves/ps_b8TxNVMvL.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1647889968333' />
      </div>
      <div className='page-first-image'>
        <img src='https://ik.imagekit.io/wilywolves/union_ZwCH03jo3p.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1647890078167' />
      </div>
      <div className='page-first-image'>
        <img src='https://ik.imagekit.io/wilywolves/firstpage_duCThsbwV.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1647890667210' />
      </div>
      <div className='page-first-image'>
        <img src='https://ik.imagekit.io/wilywolves/minnesota_Dx7UhdyEH.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1647890994972' />
      </div>
      <div className='page-first-image'>
        <img src='https://ik.imagekit.io/wilywolves/chevrolet_7pT5SrMuJ.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1647892567621' />
      </div>
      <div className='page-first-image'>
        <img src='https://ik.imagekit.io/wilywolves/coffee_ru-Iz_tlP.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1647892567576' />
      </div>
    </div>
  );
}

export default MISC;
