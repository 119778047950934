import React from "react";
import "./home.css";

function Hello() {
  return (
    <div className='hello-container'>
      <div className='title-hello'>Say Hello</div>
      <div className='socials'>
        <a href='mailto:nrtasker@gmail.com'>
          <i class='fa-solid fa-envelope'></i>
        </a>
        <a href='https://www.linkedin.com/in/neil-tasker-b4668b64/'>
          <i class='fa-brands fa-linkedin'></i>
        </a>
      </div>
    </div>
  );
}

export default Hello;
