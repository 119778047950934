import React, { useEffect } from "react";
import "./page.css";
import { Link } from "react-router-dom";

function Dodge() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='page-container'>
      <div className='page-header'>
        <div className='title-box-container'>
          <div className='project-title'>Dodge</div>
          <div className='project-catagories'> Visual Design</div>
        </div>
        <div className='page-header-image'>
          <img src='https://ik.imagekit.io/wilywolves/pattern_XXjl7rqj0ylw.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1646343066915' />
        </div>
      </div>
      <div className='page-steps-container'>
        <div className='page-steps-box'>
          <div className='steps-text-contianer'>
            <div className='steps-title'>01 — START</div>
            <div className='page-title-text'>Presentation</div>
            <div className='page-description-text'>
              I created presentation slides for Dodge automotive. Using a strict
              color scheme, layout, and typography, each slide shares a unified
              identity.
            </div>
          </div>
        </div>
      </div>

      <div className='page-first-image'>
        <img src='https://ik.imagekit.io/wilywolves/presentation_7ksnFtzwUVs.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1646344219083' />
      </div>
      <div className='page-first-image'>
        <img src='https://ik.imagekit.io/wilywolves/slide1_zvarAU8qwb.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1646343647433' />
      </div>
      <div className='page-first-image'>
        <img src='https://ik.imagekit.io/wilywolves/slide2_pt9lbddGi.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1646343647372' />
      </div>
      <div className='page-first-image'>
        <img src='https://ik.imagekit.io/wilywolves/slide3_ILJXazfEw.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1646343647360' />
      </div>
      <div className='page-first-image'>
        <img src='https://ik.imagekit.io/wilywolves/slide4_CKcncOE5k.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1646343647422' />
      </div>

      <div
        className='next-project'
        style={{
          backgroundImage:
            "url(" +
            "https://ik.imagekit.io/wilywolves/union_ZwCH03jo3p.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1647890078167" +
            ")",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          opacity: "0.75",
        }}
      >
        <Link to='/miscellaneous'>
          <div className='next-project-button'>Next Project</div>
        </Link>
      </div>
    </div>
  );
}

export default Dodge;
