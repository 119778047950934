import React, { useEffect } from "react";
import "./page.css";
import { Link } from "react-router-dom";

function Ardent() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='page-container'>
      <div className='page-header'>
        <div className='title-box-container'>
          <div className='project-title'>Ardent Ink</div>
          <div className='project-catagories'>
            {" "}
            Branding • Visual • Marketing • Web
          </div>
        </div>
        <div className='page-header-image'>
          <img src='https://ik.imagekit.io/wilywolves/ardentinkbanner_LdUbE6smc.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1641331293680' />
        </div>
      </div>
      <div className='page-steps-container'>
        <div className='page-steps-box'>
          <div className='steps-text-contianer'>
            <div className='steps-title'>01 — START</div>
            <div className='page-title-text'>Building a Brand</div>
            <div className='page-description-text'>
              I founded Ardent Ink in the summer of 2015. The purpose of the
              company was to share my illustrations with the great state of
              Michigan. Combining my talents with my father's print shop, I
              began selling merchandise on Shopify. Through creating branding,
              marketing material, and advertising I grew Ardent Ink to generate
              over 500k in total revenue over 4 years, along with a 17k
              Instagram following.
            </div>
          </div>
        </div>
      </div>
      <div className='page-first-image'>
        <img src='https://ik.imagekit.io/wilywolves/ardentsign_4PEOjN_tGLlR.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1646331414799' />
      </div>
      <div className='page-first-image'>
        <img src='   https://ik.imagekit.io/wilywolves/inspiration_qcYkFRIu4G.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1647615004598' />
      </div>
      <div className='page-first-image'>
        <img src='   https://ik.imagekit.io/wilywolves/ardentguide_mp0FHHr4CL.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1647965778696' />
      </div>

      <div className='page-first-image'>
        <img src='https://ik.imagekit.io/wilywolves/greatlakesart_EMs4fqnlGJ.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1647532561303' />
      </div>
      <div className='page-first-image'>
        <img src='https://ik.imagekit.io/wilywolves/shippingbag_5q8GOynhgj.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1647536930824' />
      </div>

      <div className='page-first-image'>
        <img src='https://ik.imagekit.io/wilywolves/pinpackage_m8YBAJe2Yv.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1647539860859' />
      </div>
      <div className='page-first-image'>
        <img src='https://ik.imagekit.io/wilywolves/discount_aTVOUzBBCV.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1647626917522' />
      </div>
      <div className='page-first-image'>
        <img src='https://ik.imagekit.io/wilywolves/thankyoucards_K-knghLGHu.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1647536930859' />
      </div>
      <div className='page-first-image'>
        <img src='https://ik.imagekit.io/wilywolves/mail_4D5FJMbqIH.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1647618817830' />
      </div>
      <div className='page-first-image'>
        <img src='https://ik.imagekit.io/wilywolves/ardentweb_EW7_1RRE5b5.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1646676553868' />
      </div>

      <div className='page-first-image'>
        <img src='https://ik.imagekit.io/wilywolves/scriptsign_SUH8dB4JTH.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1647533852167' />
      </div>
      <div className='page-first-image'>
        <img src='https://ik.imagekit.io/wilywolves/apron_PpiK6iJXUG.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1647628328599' />
      </div>
      <div className='page-first-image'>
        <img src='   https://ik.imagekit.io/wilywolves/blog_U5aeCXOsCSw.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1646752188578' />
      </div>
      <div className='page-first-image'>
        <img src='   https://ik.imagekit.io/wilywolves/bizcards_OyoDPuXpKdc.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1646673300299' />
      </div>

      <div className='video'>
        <video autoPlay muted loop playsInline>
          <source src=' https://ik.imagekit.io/wilywolves/thankyou_i_30W9Gc97.mp4?ik-sdk-version=javascript-1.4.3&updatedAt=1647534548812' />
        </video>
      </div>

      <div className='video'>
        <video autoPlay muted loop playsInline>
          <source src=' https://ik.imagekit.io/wilywolves/ardentlogo_RI9rl-3Me.mp4?ik-sdk-version=javascript-1.4.3&updatedAt=1646668521243' />
        </video>
      </div>

      <div className='page-steps-container'>
        <div className='page-steps-box'>
          <div className='steps-text-contianer'>
            <div className='steps-title'>02 — The Process</div>
            <div className='page-title-text'>Product Design</div>
            <div className='page-description-text'>
              Over four years, I created 100+ unique designs for Ardent Ink.
              Products ranged from T-shirts, sweatshirts, hats, beanies, to
              accessories such as water bottles, stickers, and pins!
            </div>
          </div>
        </div>
      </div>
      <div className='page-assets-image' id='grey'>
        <img src=' https://ik.imagekit.io/wilywolves/ardentimages_QlBsqjhlg-31.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1647362703772' />
      </div>
      <div className='page-first-image'>
        <img src='https://ik.imagekit.io/wilywolves/hockeytshirt_wrXgbKD5npj.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1647532561832' />
      </div>
      <div className='page-first-image'>
        <img src='https://ik.imagekit.io/wilywolves/camplife_JEhMKYlYxa.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1647565013814' />
      </div>
      <div className='page-first-image'>
        <img src='https://ik.imagekit.io/wilywolves/grposter_8S2WsAZCcP.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1647616532475' />
      </div>
      <div className='page-first-image'>
        <img src='https://ik.imagekit.io/wilywolves/michiganpaint_qWxsqWzUyfq.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1647532561742' />
      </div>

      <div className='page-first-image'>
        <img src='https://ik.imagekit.io/wilywolves/merch_Jj3EBT0pIA.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1646331760792' />
      </div>
      <div className='page-first-image'>
        <img src='     https://ik.imagekit.io/wilywolves/poster_LcuZqesDPr.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1647569702507' />
      </div>

      <div className='page-first-image'>
        <img src='https://ik.imagekit.io/wilywolves/ad_5OoyMj6vVi.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1647535821176' />
      </div>
      <div className='page-first-image'>
        <img src='https://ik.imagekit.io/wilywolves/totem_kjCq1wZ5dT.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1647892871180' />
      </div>
      <div className='page-first-image'>
        <img src='https://ik.imagekit.io/wilywolves/stickers_CIJJqGuLFV.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1647535993114' />
      </div>
      <div className='page-first-image'>
        <img src='https://ik.imagekit.io/wilywolves/patch_dX1vNaETy99N.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1647442572878' />
      </div>

      <div className='page-first-image'>
        <img src='https://ik.imagekit.io/wilywolves/oxford_Gp_hC3xWN.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1646674807205' />
      </div>
      <div className='page-first-image'>
        <img src='https://ik.imagekit.io/wilywolves/ads_R6UbDt9V-c.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1647532561760' />
      </div>

      <div
        className='next-project'
        style={{
          backgroundImage:
            "url(" +
            "https://ik.imagekit.io/wilywolves/wilywolvesheader_oTun8U4xs.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1640893273211" +
            ")",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          opacity: "0.75",
        }}
      >
        <Link to='/wilywolves'>
          <div className='next-project-button'>Next Project</div>
        </Link>
      </div>
    </div>
  );
}

export default Ardent;
